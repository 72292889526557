body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  font-size: 0.72rem !important;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0.25rem 0;
}

h6 { font-size: 0.775rem; }
h5 { font-size: 0.97rem; }
h4 { font-size: 1.21rem; }
h3 { font-size: 1.51rem; }
h2 { font-size: 1.89rem; }
h1 { font-size: 2.36rem; }
small { font-size: 0.57rem; }

button, a {
  font-family: inherit;
  font-size: inherit;
}

.heading {
  font-size: 2.617rem;
  font-weight: bold;
  margin: 0 0 0.5rem 0;
}

.sub-heading {
  font-size: 1.618rem;
  font-weight: bold;
  margin: 0 0 0.5rem 0;
}

.cursor-pointer {
  cursor: pointer !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  flex: 1 1 100%;
  margin: auto;
  
  width: 100%;
}

.container .fluid {
  max-width: 100%;
}

/* HEIGHT & WIDTH */
.h-100 {
  height: 100% !important;
}

.w-100 {
  width: 100% !important;
}

/* OVERFLOWS */
.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-y-auto {
  overflow-x: auto !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

/* TEXT & FONTS */
.text-truncate {
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-x-sm {
  font-size: x-small !important;
}

.text-sm {
  font-size: small !important;
}

.text-lg {
  font-size: large !important;
}

.text-x-lg {
  font-size: x-large !important;
}

.text-xx-lg {
  font-size: xx-large !important;
}

.text-lightweight {
  font-weight: 400 !important;
}

.text-strong {
  font-weight: bold;
}

.gray-text {
  color: gray !important;
}

.line-height-sm {
  line-height: 0.9 !important;
}

/* POSITIONS */
.position-relative {
  position: relative !important;
}

/* FLEX */
.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.flex-column {
  flex-direction: column !important;
}

.justify-center {
  justify-content: center !important;
}

.justify-evenly {
  justify-content: space-evenly !important;
}

.justify-end {
  justify-content: flex-end !important;
}

.justify-between {
  justify-content: space-between !important;
}

.align-center {
  align-items: center !important;
}

.align-end {
  align-items: flex-end !important;
}


.alignself-center {
  align-self: center !important;
}

.align-baseline {
  align-items: baseline !important;
}

/* PADDING */
.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: .25rem !important;
}

.p-2 {
  padding: .5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem 1.5rem !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pr-1 {
  padding-right: .25rem !important;
}

.pr-2 {
  padding-right: .5rem !important;
}

.pr-3 {
  padding-right: 1rem !important;
}

.pr-4 {
  padding-right: 1.5rem !important;
}

.pr-5 {
  padding-right: 3rem !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pl-1 {
  padding-left: .25rem !important;
}

.pl-2 {
  padding-left: .5rem !important;
}

.pl-3 {
  padding-left: 1rem !important;
}

.pl-4 {
  padding-left: 1.5rem !important;
}

.pl-5 {
  padding-left: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: .25rem !important;
}

.pt-2 {
  padding-top: .5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: .25rem !important;
}

.pb-2 {
  padding-bottom: .5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.py-0 {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.py-1 {
  padding-bottom: .25rem !important;
  padding-top: .25rem !important;
}

.py-2 {
  padding-bottom: .5rem !important;
  padding-top: .5rem !important;
}

.py-3 {
  padding-bottom: 1rem !important;
  padding-top: 1rem !important;
}

.py-4 {
  padding-bottom: 1.5rem !important;
  padding-top: 1.5rem !important;
}

.py-5 {
  padding-bottom: 3rem !important;
  padding-top: 3rem !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.px-1 {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

.px-2 {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.px-5 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

/* MARGIN */
.m-auto {
  margin: auto !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: .25rem !important;
}

.m-2 {
  margin: .5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mr-auto{
  margin-right: auto !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-1 {
  margin-right: .25rem !important;
}

.mr-2 {
  margin-right: .5rem !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

.mr-4 {
  margin-right: 1.5rem !important;
}

.mr-5 {
  margin-right: 3rem !important;
}

.ml-auto {
  margin-left: auto !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-1 {
  margin-left: .25rem !important;
}

.ml-2 {
  margin-left: .5rem !important;
}

.ml-3 {
  margin-left: 1rem !important;
}

.ml-4 {
  margin-left: 1.5rem !important;
}

.ml-5 {
  margin-left: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: .25rem !important;
}

.mt-2 {
  margin-top: .5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: .25rem !important;
}

.mb-2 {
  margin-bottom: .5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-bottom: auto !important;
  margin-top: auto !important;
}

.my-0 {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

.my-1 {
  margin-bottom: .25rem !important;
  margin-top: .25rem !important;
}

.my-2 {
  margin-bottom: .5rem !important;
  margin-top: .5rem !important;
}

.my-3 {
  margin-bottom: 1rem !important;
  margin-top: 1rem !important;
}

.my-4 {
  margin-bottom: 1.5rem !important;
  margin-top: 1.5rem !important;
}

.my-5 {
  margin-bottom: 3rem !important;
  margin-top: 3rem !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mx-1 {
  margin-left: .25rem !important;
  margin-right: .25rem !important;
}

.mx-2 {
  margin-left: .5rem !important;
  margin-right: .5rem !important;
}

.mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.mx-5 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}


/* INPUT  */
.MuiOutlinedInput-notchedOutline {
  border-color: #00276c;
}


/* simple loader */
/* .simpler-loader {
  border: .2em solid currentcolor;
  border-bottom-color: transparent;
  border-radius: 50%;
  animation: 1s animated-loader linear infinite;
  position: relative;
} */

/* @keyframes animated-loader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
} */

@media only screen and (min-width: 960px) {
  .container {
    max-width: 900px;
    padding: 1rem;
  }

  .p-5 {
    padding: 3rem !important;
  }

  /* body {
    font-size: 1rem !important;
  } */
  
}

@media only screen and (min-width: 1264px) {
  .container {
    max-width: 1064px;
  }

  /* scrollbar */
  ::-webkit-scrollbar{
    width: 5px;
  }

  ::-webkit-scrollbar:horizontal {
    height: 5px;
  }

  ::-webkit-scrollbar-track{
    background: transparent;
  }

  ::-webkit-scrollbar-thumb{
    background: rgba(15, 113, 138, 0.4);
  }

  ::-webkit-scrollbar-thumb:hover{
    background: rgba(15, 113, 138, 0.7);
  }
}

@media only screen and (min-width: 1440px) {
  .container {
    max-width: 1240px;
  }
}

@media only screen and (min-width: 1920px) {
  .container {
    max-width: 1785px;
  }
  /* body {
    font-size: 1.2rem !important;
  } */
}